<template>
  <div class="profile-wrapper">
    <ul class="nav nav-tabs nav-pills nav-fill" id="myTab" role="tablist">
      <li class="nav-item">
        <a
          class="nav-link active"
          id="user-profile-tab"
          data-toggle="tab"
          href="#user-profile"
          role="tab"
          aria-controls="user-profile"
          aria-selected="true"
        >
          <img src="../../../assets/images/white-user.png" alt="" />
          User Details
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          id="company-profile-tab"
          data-toggle="tab"
          href="#company-profile"
          role="tab"
          aria-controls="company-profile"
          aria-selected="false"
        >
          <img src="../../../assets/images/company-profile-icon.png" alt="" />
          My Company Details</a
        >
      </li>
    </ul>

    <!-- <div
      class="tab-pane fade"
      id="profile"
      role="tabpanel"
      aria-labelledby="profile-tab"
    >
      <div class="transaction-wrapper">
        <div class="card-image">
          <img src="../../assets/images/credit card.png" alt="" />
        </div>
        <div class="transaction-details">
          <div class="transaction-top">
            <h4>Transactions</h4>
            <button type="button">VIEW ALL</button>
          </div>
          <div class="transaction-content">
            <div class="transaction-data">
              <div class="date-number">
                <span class="date">March, 01, 2021</span>
                <br />
                <span class="number">#MS-415646</span>
              </div>
              <div class="price-save">
                <span class="price">$180</span>
                <span class="save">PDF</span>
              </div>
            </div>
          </div>
          <div class="transaction-content">
            <div class="transaction-data">
              <div class="date-number">
                <span class="date">March, 01, 2021</span>
                <br />
                <span class="number">#MS-415646</span>
              </div>
              <div class="price-save">
                <span class="price">$180</span>
                <span class="save">PDF</span>
              </div>
            </div>
          </div>
          <div class="transaction-content">
            <div class="transaction-data">
              <div class="date-number">
                <span class="date">March, 01, 2021</span>
                <br />
                <span class="number">#MS-415646</span>
              </div>
              <div class="price-save">
                <span class="price">$180</span>
                <span class="save">PDF</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="payment-method">
        <div class="payment-method-heading">
          <h4>Payment Method</h4>
          <router-link class="custom-btn4" to="/packages"
            >Add New Card</router-link
          >
        </div>
        <div class="payment-card">
          <div class="card-wrapper">
            <img src="../../assets/images/card-2.png" alt="" />
          </div>
          <div class="card-wrapper">
            <img src="../../assets/images/card-2.png" alt="" />
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "ProfileTop",
};
</script>

<style></style>
